import { COLORS, ContentIcons, LoadingIndicator, PageWidth } from "@laerdal/life-react-components";


import styled from "styled-components";
import { useEffect, useState } from "react";
import ArticleApi from "../../services/api/ArticleApi";
import { ProductLineDetailsDto } from "../../model/dto/productLineDetailsDto";
import { useParams } from "react-router-dom";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import Subtopics from "../../commonComponents/Subtopics";
import GetToKnow from "../../commonComponents/GetToKnow";
import Faqs from "../../commonComponents/Faqs";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import Topics from "../../commonComponents/Topics";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ProductLinePage = () => {
    const { categoryLink, productLink } = useParams<{ categoryLink: string, productLink: string }>();

    const [productLineLoading, setProductLineLoading] = useState<boolean>(true);
    const [productLine, setProductLine] = useState<ProductLineDetailsDto | undefined>(undefined);

    useEffect(() => {
        setProductLineLoading(true);
        setProductLine(undefined);

        if(categoryLink && productLink)
        {
            ArticleApi
              .GetProductLine(categoryLink, productLink)
              .then((data) => {
                setProductLineLoading(false);
                setProductLine(data);
              })
        }
    }, [categoryLink, productLink]);

    return (
      <>
        { productLineLoading &&
            <LoadingIndicator />
        }

        { productLine &&
            <>
                <ProductTopSection
                    title={productLine.displayName}
                    description={productLine.descripton}
                    asset={productLine.mainImageUrl}
                    breadcrumb={[{
                        text: 'Home',
                        link: '/'
                    },{
                        text: productLine.categoryName,
                        link: `/category/${categoryLink}`
                    },{
                        text: productLine.displayName
                    }]}
                    buttonLink= {productLine.productDownloadsUrl ?? undefined}
                />
                <FullNeutralPageContainer>
                    <PageContainer>
                        <Subtopics
                            items={
                                productLine.videosPageUrl ? [{
                                    text: "How-to videos",
                                    icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                                    link: productLine.videosPageUrl
                                }].concat(productLine.components.map((x)=> ({
                                    text: x.name,
                                    icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                                    link: `component/${x.link}`
                                }))) : productLine.components.map((x)=> ({
                                    text: x.name,
                                    icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                                    link: `component/${x.link}`
                                }))
                            }
                        />
                        <GetToKnow 
                            title={`Get to know your ${productLine.displayName}`}
                            items={
                                productLine.getToKnow.map((x)=> ({
                                    title: x.name,
                                    subtitle: "",
                                    link: `/articles/Knowledge/${x.link}`
                                }))
                            }
                        />
                    </PageContainer>
                </FullNeutralPageContainer>
                        
                <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                    <PageContainer>
                        <CantFindWhatYouNeed />

                        {   productLine.faqs.length > 0 &&
                            <Faqs 
                            title="FAQs"
                            items={
                                productLine.faqs.map((x)=> ({
                                    text: x.name,
                                    link: `/articles/Knowledge/${x.link}`
                                }))
                            }/>
                        }

                        <Topics 
                            title="Similar products"
                            items={
                                productLine.similarProducts.map((x)=> ({
                                    text: x.name,        
                                    asset: x.mainImageUrl,
                                    heightPx: "144px",
                                    link: `/category/${categoryLink}/product/${x.link}`
                                }))
                            }
                        />

                        <CantFindCreateCase />
                    </PageContainer>
                </PageWidth>
            </>
        }

        
      </>);
  };
  
  export default ProductLinePage;